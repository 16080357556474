<template>
  <div
    class="d-flex flex-column flex-grow-1"
    v-if="document.id"
  >
    <document-toolbar
      :tab="tab"
      :files.sync="document.attachments"
      :links.sync="document.links"
      :show-link-manager="!documentIsNew"
      @close-pop-up="refreshCache()"
      @update-links="refreshCache()"
      :revert="refreshDocument"
      :save="saveDocument"
      :hasComment="!!(document.description || '').length || !!(document.custom || '').length"
      :readonly-file-manager="!document.canUpdate"
      :labels.sync="document.labels"
      :show-labels="!documentIsNew"
      show-file-manager
      comment
      v-on="$listeners"
    >
      <dispute-status
        class="ml-3"
        :status="statusWithWorkflow"
        :outlinedChip="true"
      >
        <template v-slot:status>
          <div
            class="mr-1"
            v-if="statusWithWorkflow.workflow.name"
          >
            <v-icon
              small
              class="mr-1"
            >{{$icon('i.Workflow')}}</v-icon>
            <span>{{statusWithWorkflow.workflow.name}}</span>
          </div>
          <span
            v-if="documentIsNew || initialWorkflowId !== document.workflowId || !document.canUpdate"
            class="ml-3 secondary--text font-weight-bold"
          >
            <document-name-ref
              v-if="document.statusDisputeId"
              :id="document.statusDisputeId"
              :cache-type="cacheTypeStatusDispute"
            />
          </span>
          <v-select
            v-else
            class="mx-1"
            solo
            hide-details
            :items="availableStatuses"
            item-text="name"
            item-value="id"
            v-model="statusDisputeId"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
            :background-color="statusWithWorkflow.isBeyondTarget ? 'red': null"
          />
        </template>
      </dispute-status>
      <account-ref
        v-if="document.accountId !== $route.params.id"
        :icon-props="{small: true}"
        :id="document.accountId"
        class="clickable secondary--text"
        @click.native.stop="$router.push('/accounts/' + document.accountId).catch(() => {})"
      />
    </document-toolbar>
    <document-in-tab>
      <v-form
        ref="form"
        @input="validateForm"
        :disabled="!document.canUpdate || statusWillCloseDispute"
      >
        <grid item-min-width="300px">
          <comment
            :text.sync="document.description"
            :disabled="!document.canUpdate || statusWillCloseDispute"
            :showComment="tab.showComment"
            :counter="2000"
            :rules="commentRules"
            class="mb-4"
          />
          <comment
            :text.sync="document.custom"
            :disabled="!document.canUpdate || statusWillCloseDispute"
            :showComment="tab.showComment"
            :counter="2000"
            :rules="commentRules"
            :label="$t('t.AdditionalInformation')"
            class="mb-4"
          />
        </grid>
        <v-card class="mb-4">
          <v-card-title>
            <div
              class="minimum-width text-h5"
              v-if="document.invoices.length"
            >
              <span class="primary--text font-weight-bold">{{document.invoices.length}}</span>
              <span v-if="document.invoices.length == 1"> {{$t('t.InvoiceSelected')}} </span>
              <span v-else> {{$t('t.InvoicesSelected')}} </span>
              <span class="primary--text font-weight-bold">{{formatCurrency(document.amount, document.currency)}}</span>
            </div>
            <div
              class="minimum-width text-h5"
              v-else
            >
              {{$t('t.DisputeOnAccount')}}
            </div>
          </v-card-title>
          <v-card-text>
            <disputes-amount
              :dispute.sync="document"
              :statusWillCloseDispute="statusWillCloseDispute"
            />
          </v-card-text>
        </v-card>
        <grid item-min-width="300px">
          <v-card>
            <v-card-title>
              {{$t('t.Informations')}}
            </v-card-title>
            <v-card-text>
              <disputes-infos
                :dispute.sync="document"
                :statusWillCloseDispute="statusWillCloseDispute"
                @update:reason="calculateWorkflow"
              />
              <custom-fields-columns
                :custom-fields.sync="customFieldsRequired"
                :disabled="statusWillCloseDispute"
                :cols="4"
              />
              <custom-fields-columns
                :custom-fields.sync="customFields"
                :disabled="statusWillCloseDispute"
                :cols="4"
              />
            </v-card-text>
          </v-card>
          <v-card :disabled="!document.disputeReasonId">
            <v-card-title>
              {{$t('t.Contributors')}}
            </v-card-title>
            <v-card-text>
              <disputes-contributors
                :dispute.sync="document"
                :statusWillCloseDispute="statusWillCloseDispute"
                @update:dispute="validateForm"
                ref="contributors"
              />
              <disputes-notification
                class="mt-4"
                :dispute.sync="document"
                :statusWillCloseDispute="statusWillCloseDispute"
                ref="confirmations"
              />
            </v-card-text>
          </v-card>
        </grid>
      </v-form>
    </document-in-tab>
  </div>
</template>
<script>

import DocumentEditor from '@/mixins/document-editor'
import customFieldsController from '@/customFieldsController'

export default {
  mixins: [DocumentEditor],
  components: {
    Grid: () => import('@/components/grid'),
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    Comment: () => import('@/components/comment'),
    DisputesAmount: () => import('./disputes-amount'),
    DisputesInfos: () => import('./disputes-infos'),
    DisputesContributors: () => import('./disputes-contributors'),
    DisputesNotification: () => import('./disputes-notification'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    CustomFieldsColumns: () => import('@/components/custom-fields-columns'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    DisputeStatus: () => import('@/components/dispute-collaboration-status')
  },
  created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
      cacheType: DocumentEditor.CacheType.DisputeDetail,
      cacheTypeStatusDispute: DocumentEditor.CacheType.StatusDisputeRef,
      commentRules: [value => (value?.length ?? 0) <= 2000],
      http: this.$http().debounced(),
      statusWillCloseDispute: false,
      status: {
        canApprove: true,
        canReject: true,
        canCancel: true,
        statuses: [],
        updateCOTA: false
      },
      required: [
        v => !!v || this.$t('t.IsRequired')
      ],
      workflowName: null
    }
  },
  computed: {
    id () {
      return this.tab.documentId
    },
    initialWorkflowId () {
      return this.cache?.doc?.workflowId
    },
    customFields: {
      get () {
        return this.document.customFields.filter(c => !c.isRequired)
      },
      set (disputeCustomFields) {
        this.document = Object.assign({}, this.document, { customFields: [...disputeCustomFields, ...this.customFieldsRequired] })
        this.calculateWorkflow()
      }
    },
    customFieldsRequired: {
      get () {
        return this.document.customFields.filter(c => c.isRequired)
      },
      set (disputeCustomFieldsRequired) {
        this.document = Object.assign({}, this.document, { customFields: [...disputeCustomFieldsRequired, ...this.customFields] })
        this.calculateWorkflow()
      }
    },
    statusDisputeId: {
      get () {
        return this.document.statusDisputeId
      },
      set (statusDisputeId) {
        this.document = Object.assign({}, this.document, { statusDisputeId })
        this.updateDisputeStatuses()
      }
    },
    statusWithWorkflow () {
      return {
        id: this.statusDisputeId,
        targetDate: this.document.targetDate,
        isBeyondTarget: this.document.isBeyondTarget,
        statusChangedAt: this.document.statusChangedAt,
        workflow: {
          name: this.workflowName
        },
        allocationDispute: {
          status: this.document.allocationDisputeStatus,
          date: this.document.allocationDisputeStatusChangedAt
        }
      }
    },
    daysSummary () {
      if (!this.document.targetDate) {
        return ''
      }
      const daysBeforeTarget = this.moment(this.document.targetDate).diff(this.moment(), 'days')
      return daysBeforeTarget >= 0
        ? daysBeforeTarget + 1 + ' ' + this.$t('t.DaysBeforeDate')
        : -daysBeforeTarget + ' ' + this.$t('t.DaysPastDue')
    },
    closeStatuses () {
      const statuses = []
      if (this.status.canApprove || this.cache?.doc?.statusDisputeId === this.document.approvedStatus) {
        statuses.push({
          id: this.document.approvedStatus,
          name: this.$t('t.Approve'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusDisputeId !== this.document.approvedStatus
        })
      }
      if (this.status.canReject || this.cache?.doc?.statusDisputeId === this.document.rejectedStatus) {
        statuses.push({
          id: this.document.rejectedStatus,
          name: this.$t('t.Reject'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusDisputeId !== this.document.rejectedStatus
        })
      }
      if (this.status.canCancel || this.cache?.doc?.statusDisputeId === this.document.canceledStatus) {
        statuses.push({
          id: this.document.canceledStatus,
          name: this.$t('t.Delete'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusDisputeId !== this.document.canceledStatus
        })
      }
      return statuses
    },
    availableStatuses () {
      const statuses = this.status.statuses.slice()
      if (this.closeStatuses.length) {
        statuses.push({ divider: true }, ...this.closeStatuses)
      }

      return statuses
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        // Preset invoice when creating a new dispute from invoices (search or document)
        this.document = Object.assign({}, this.document, {
          id: this.tab.documentId,
          invoices: [],
          amount: 0,
          currency: this.$store.getters.currentUser.currency,
          accountId: this.$route.params.id,
          attachments: [],
          createBy: this.$store.getters.currentUser.id,
          groupContributorId: null,
          description: null,
          owner: { id: this.$store.getters.currentUser.id },
          participantList: [],
          workflowId: null,
          confirmations: [],
          reminders: [],
          creatorCanUpdate: true,
          creatorCanClose: true,
          nbDaysBeforeNotification: 1,
          ownerCanUpdate: true,
          ownerCanClose: true,
          participantsCanUpdate: true,
          participantsCanClose: true,
          customFields: [],
          disputeReasonId: null,
          statusDisputeId: undefined,
          isBeyondTarget: false,
          canUpdate: true,
          targetDate: undefined
        })

        if (Array.isArray(this.tab.invoices)) {
          this.document.invoices = this.tab.invoices.map(invoice => Object.assign(invoice, { amountDisputed: invoice.disputeAmountAllowed }))
          this.document.amount = this.document.invoices.reduce((acc, i) => acc + i.amountDisputed, 0)
          this.document.currency = this.document.invoices[0].currency
        }

        const [dsr, dcfr] = await Promise.all([
          this.$http().get('/core/v6/settings/dispute'),
          this.$http().get('/core/v6/settings/custom-fields/dispute')
        ])
        this.document = Object.assign({}, this.document, dsr.data, { customFields: dcfr.data.customFields })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.number}` })

        // await this.$http().patch('/disputeParticipants/' + this.document.id)
        const dsr = await this.$http().get('/core/v6/settings/dispute')
        this.document = Object.assign({}, this.document,
          {
            creatorCanCloseLocked: dsr.data.creatorCanCloseLocked,
            creatorCanUpdateLocked: dsr.data.creatorCanUpdateLocked,
            ownerCanCloseLocked: dsr.data.ownerCanCloseLocked,
            ownerCanUpdateLocked: dsr.data.ownerCanUpdateLocked,
            participantsCanCloseLocked: dsr.data.participantsCanCloseLocked,
            participantsCanUpdateLocked: dsr.data.participantsCanUpdateLocked
          })

        if (!this.document.isClosed) {
          await this.calculateWorkflow()
        }

        if (this.document.description?.length || this.document.custom?.length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }

        this.statusWillCloseDispute = false

        if (this.initialWorkflowId !== this.document.workflowId) {
          return this.saveDocument()
        }
      }

      this.validateForm()
    },
    async calculateWorkflow () {
      if (!this.document.disputeReasonId) {
        return
      }
      try {
        const workflow = (await this.http.post('/core/v6/dispute/compute-workflow', await this.disputeToJSON())).data
        this.status = Object.assign({}, this.status, workflow.computeStatuses)
        this.status.statuses.sort((a, b) => a.sequence > b.sequence)
        this.workflowName = this.document.workflowId ? workflow.name : null

        if (this.document.workflowId !== workflow.id || !this.document.statusDisputeId) {
          this.document = Object.assign({}, this.document, {
            workflowId: workflow.id
          })
          this.workflowName = this.document.workflowId ? workflow.name : null

          const newStatus = this.status.statuses[0]
          if (!newStatus) return
          this.document = Object.assign({}, this.document, { statusDisputeId: newStatus.id, groupContributorId: newStatus.groupContributorId })

          this.$refs?.contributors?.update()

          this.statusWillCloseDispute = newStatus.isClosedStatus
        }
      } catch (e) {
        if (e !== 'Debounced') {
          throw e
        }
      }
    },
    updateDisputeStatuses () {
      const currentStatus = [...this.status.statuses, ...this.closeStatuses].find(s => s.id === this.document.statusDisputeId) || this.status.statuses[0]
      if (!currentStatus) return
      this.document = Object.assign({}, this.document, { statusDisputeId: currentStatus.id, groupContributorId: currentStatus.groupContributorId })
      this.statusWillCloseDispute = currentStatus.isClosedStatus
      if (this.document.workflowId) {
        this.$refs?.contributors?.update()
      }
    },
    validateForm () {
      this.$waitFor(() => this.$refs?.form)
        .then(form => this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.document.canUpdate && form?.validate() && this.document.owner?.id) }))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    disputeToJSON () {
      // Remap participants
      const formatedParticipants = [this.document.owner, ...this.document.participantList].map((p, index) => {
        if (p) {
          return {
            id: this.uuid(),
            userId: p.userRole ? null : p.id,
            disputeId: this.document.id,
            isOwner: index === 0,
            indexOrder: index === 0 ? undefined : p.indexOrder,
            userRole: p.userRole
          }
        } else return null
      })

      // Remap invoices
      const formatedInvoices = this.document.invoices.map(i => ({
        id: i.id,
        disputeId: this.document.id,
        disputeAmount: i.amountDisputed
      }))

      const formatedAttachments = this.document.attachments?.map(a => a.id)

      return Object.assign(
        {
          id: this.document.id,
          accountId: this.document.accountId,
          attachments: formatedAttachments,
          confirmations: this.document.confirmations.map(c => ({ userId: c.userId || c.id })),
          reminders: this.document.reminders.map(c => ({ userId: c.userId || c.id })),
          creator: this.document.creator,
          creatorCanUpdate: this.document.creatorCanUpdate,
          creatorCanClose: this.document.creatorCanClose,
          currency: this.document.currency,
          custom: this.document.custom,
          description: this.document.description,
          disputeAmount: this.document.amount,
          disputeInvoices: formatedInvoices,
          disputeReasonId: this.document.disputeReasonId,
          groupContributorId: this.document.groupContributorId ?? null,
          nbDaysBeforeNotification: this.document.reminders.length ? this.document.nbDaysBeforeNotification : 0,
          owner: this.document.owner,
          ownerCanUpdate: this.document.ownerCanUpdate,
          ownerCanClose: this.document.ownerCanClose,
          participants: formatedParticipants,
          participantsCanUpdate: this.document.participantsCanUpdate,
          participantsCanClose: this.document.participantsCanClose,
          statusDisputeId: this.document.statusDisputeId,
          targetDate: this.document.targetDate,
          updateCota: !this.document.allocationDisputeStatus && (
            (this.status.updateCOTA && this.statusWillCloseDispute) ||
            this.status.statuses.find(s => s.id === this.document.statusDisputeId)?.updateCOTAWithSaveAction
          ),
          workflowId: this.document.workflowId
        },
        customFieldsController.convertForJSON(this.document.customFields))
    },
    async save () {
      if (this.documentIsNew) {
        return await this.$http().post('/core/v6/dispute', await this.disputeToJSON())
      } else {
        return await this.$http().put(`/core/v6/dispute/${this.document.id}`, await this.disputeToJSON())
      }
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>
